export const GetRedirectMap = () => {
    return [
        {
            "from": "/yogurtinfo/yogurtdetail?bid=7",
            "to": "/content/introduction/info/1"
        },
        {
            "from": "/Location",
            "to": "/store"
        },
        {
            "from": "/Shop/ProductList",
            "to": "/products/home"
        },
        {
            "from": "/Shop/ProductType?Type=A",
            "to": "/products/f"
        },
        {
            "from": "/Shop/ProductType?Type=E",
            "to": "/products/home#subscription"
        },
        {
            "from": "/News",
            "to": "/content/news"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=A201707130006",
            "to": "/products/info/f2022101303?category_id=6"
        },
        {
            "from": "/Shop/ProductType?Type=F",
            "to": "/discount/1"
        },
        {
            "from": "/Season/Detail/51",
            "to": "/content/review/info/866"
        },
        {
            "from": "/News/Detail/44",
            "to": "/content/page/sales-channel"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=F201906130001",
            "to": "/"
        },
        {
            "from": "/Recipe",
            "to": "/content/recipe"
        },
        {
            "from": "/Shop/ProductType?Type=H",
            "to": "/products/i"
        },
        {
            "from": "/News/Detail/81",
            "to": "/content/news/info/874"
        },
        {
            "from": "/YogurtInfo/YogurtDetail?bid=35",
            "to": "/content/blog/info/884"
        },
        {
            "from": "/News/Detail/26",
            "to": "/content/page/recycle"
        },
        {
            "from": "/About",
            "to": "/content/page/brand"
        },
        {
            "from": "/YogurtInfo/YogurtDetail?bid=24",
            "to": "/"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=A202008070001",
            "to": "/products/info/f2022101305?category_id=6"
        },
        {
            "from": "/News/Detail/27",
            "to": "/content/activity/info/916"
        },
        {
            "from": "/About/BlogDetail?bid=465214193",
            "to": "/content/diary/info/680"
        },
        {
            "from": "/About/BlogDetail?bid=561022105",
            "to": "/content/diary/info/659"
        },
        {
            "from": "/YogurtInfo/YogurtDetail?bid=21",
            "to": "/"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=A201801150001",
            "to": "/products/info/f2022101307?category_id=6"
        },
        {
            "from": "/News/Detail/74",
            "to": "/store/3/37#store"
        },
        {
            "from": "/YogurtInfo/YogurtDetail?bid=12",
            "to": "/"
        },
        {
            "from": "/News/Detail/66",
            "to": "/content/news/info/880"
        },
        {
            "from": "/Shop/ProductType?Type=C",
            "to": "/products/g"
        },
        {
            "from": "/Shop/ProductType?Type=B",
            "to": "/products/h"
        },
        {
            "from": "/Season/Detail/46",
            "to": "/content/review/info/863"
        },
        {
            "from": "/Season/Detail/56",
            "to": "/content/review/info/864"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=H202101080001",
            "to": "/products/info/i2022101301?category_id=9"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=H202206090001",
            "to": "/products/info/i2022101304?category_id=9"
        },
        {
            "from": "/News/Detail/69",
            "to": "/"
        },
        {
            "from": "/About/BlogDetail?bid=496028894",
            "to": "/content/diary/info/668"
        },
        {
            "from": "/Shop/ProductType?Type=J",
            "to": "/"
        },
        {
            "from": "/News/Detail/84",
            "to": "/store/3/42#store"
        },
        {
            "from": "/About/BlogDetail?bid=452344865",
            "to": "/content/diary/info/715"
        },
        {
            "from": "/YogurtInfo/YogurtDetail?bid=8",
            "to": "/"
        },
        {
            "from": "/Shop/LongTernProductInfo?ProductId=E201708020001",
            "to": "/"
        },
        {
            "from": "/YogurtInfo/YogurtDetail?bid=16",
            "to": "/"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=A202205200001",
            "to": "/products/info/f2022101306?category_id=6"
        },
        {
            "from": "/About/Blog",
            "to": "/content/diary"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=B201707130001",
            "to": "/products/info/h2022101301?category_id=8"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=J202205120007",
            "to": "/products/info/j2022120101?category_id=10"
        },
        {
            "from": "/About/BlogDetail?bid=452163716",
            "to": "/content/diary/info/716"
        },
        {
            "from": "/Season/Detail/52",
            "to": "/content/review/info/871"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=B201707140001",
            "to": "/products/info/h2023013003?category_id=8"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=B202210260001",
            "to": "/"
        },
        {
            "from": "/Shop/ProductInfo?ProductId=H202012100001",
            "to": "/"
        },
        {
            "from": "/Story",
            "to": "/"
        },
        {
            "from": "/Shop/ProductType?Type=H&utm_source=printout&utm_medium=QRcode&utm_campaign=7Dayspack",
            "to": "/products/i"
        }
    ]
}