<nb-layout [restoreScrollTop]="false">
    <!-- 語系切換 -->
    <!-- <div class="col-lg-2 col-lang">
			<app-lang class="px-1"></app-lang>
	</div> -->

    <!-- Header -->
    <nb-layout-header>
        <header class="header">
            <div
                class="menu-main"
                [ngClass]="{ 'has-cart-count': cartService.cart_count >= '1' }"
            >
                <ul class="nav">
                    <!-- 小畫面菜單bar條按鈕-->
                    <li class="nav-item order-lg-4">
                        <button
                            type="button"
                            class="nav-link menu-toggler"
                            (click)="toggleMainMenu()"
                        >
                            <picture [hidden]="menu_open">
                                <!-- mobile -->
                                <source
                                    media="(max-width: 991px)"
                                    srcset="/assets/images/icon/menu_m.svg"
                                />
                                <!-- desktop -->
                                <img src="/assets/images/icon/menu.png" />
                            </picture>
                            <picture [hidden]="!menu_open">
                                <!-- mobile -->
                                <source
                                    media="(max-width: 991px)"
                                    srcset="
                                        /assets/images/icon/menu_close_m.svg
                                    "
                                />
                                <!-- desktop -->
                                <img src="/assets/images/icon/menu_close.png" />
                            </picture>
                        </button>
                    </li>

                    <li class="nav-item order-lg-1">
                        <a
                            [routerLink]="['/']"
                            class="nav-link"
                            (click)="closeMenu()"
                        >
                            <span class="d-lg-none">
                                <img
                                    src="/assets/images/logo.svg?v=20221215"
                                    class="home-icon"
                                />
                            </span>
                            <span class="d-none d-lg-block">
                                <img src="/assets/images/icon/home_word.png" />
                                <!-- <img src="/assets/images/icon/home.png"><br>
            首頁 -->
                            </span>
                        </a>
                    </li>

                    <!-- 登入／註冊 -->
                    <li *ngIf="!user" class="nav-item nav-item-user order-lg-5">
                        <a
                            [routerLink]="['/auth/login']"
                            class="nav-link"
                            (click)="closeMenu()"
                        >
                            <img
                                src="/assets/images/icon/user.png"
                                class="user-icon"
                            />
                        </a>
                    </li>

                    <!-- 已登入/登出 -->
                    <li *ngIf="user" class="nav-item nav-item-user order-lg-5">
                        <!--p>{{ user.name }}</p><br-->
                        <a
                            [routerLink]="['/member/info']"
                            class="nav-link"
                            (click)="closeMenu()"
                        >
                            <img
                                src="/assets/images/icon/user_active.png"
                                class="user-icon"
                            />
                        </a>
                        <!--a (click)="authService.logout()" class="nav-link d-none d-lg-block" (click)="closeMenu()">登出</a-->
                    </li>

                    <li class="nav-item order-lg-2 d-none d-lg-block">
                        <a
                            [routerLink]="production == true ? ['/products/f'] : ['/products/b']"
                            class="nav-link"
                            (click)="closeMenu()"
                        >
                            <span>
                                <img src="/assets/images/icon/shop_word.png" />
                                <!-- <img src="/assets/images/icon/shop.png"><br>
            商城 -->
                            </span>
                        </a>
                    </li>

                    <li class="nav-item order-lg-2 d-none d-lg-block">
                        <a
                            [routerLink]="production == true ? ['/products/k']: ['/products/f']"
                            class="nav-link"
                            (click)="closeMenu()"
                        >
                            <span>
                                <img
                                    src="/assets/images/icon/subscription_word.png"
                                />
                                <!-- <img src="/assets/images/icon/subscription.png"><br>
            訂閱 -->
                            </span>
                        </a>
                    </li>

                    <li class="nav-item order-lg-2 d-none d-lg-block">
                        <a
                            [routerLink]="['/products/a']"
                            class="nav-link"
                            (click)="closeMenu()"
                        >
                            <span>
                                <img
                                    src="/assets/images/icon/shopcash_word.png"
                                />
                                <!-- <img src="/assets/images/icon/shopcash.png"><br>
            點數 -->
                            </span>
                        </a>
                    </li>

                    <li class="nav-item order-lg-2 d-lg-none">
                        <a
                            [routerLink]="['/products/home']"
                            class="nav-link"
                            (click)="closeMenu()"
                        >
                            SHOP
                        </a>
                    </li>

                    <!-- 購物車 -->
                    <li class="nav-item order-lg-3">
                        <button
                            type="button"
                            class="nav-link"
                            (click)="toggleCart()"
                        >
                            <!--button type="button" class="nav-link" (click)="cartService.openCartDialog()"-->
                            <img
                                src="/assets/images/icon/cart.png"
                                class="cart-icon"
                            />
                            <span
                                *ngIf="cartService.cart_count >= '1'"
                                class="cart-count"
                            >
                                {{ cartService.cart_count }}
                            </span>
                        </button>
                    </li>
                </ul>
            </div>

            <!-- menu -->
            <div *ngIf="menu_open" class="menu-sidebar">
                <app-product-menu
                    (routeClick)="toggleMainMenu()"
                    [user]="user"
                ></app-product-menu>
            </div>

            <!-- 購物車 -->
            <div *ngIf="cart_open" class="menu-sidebar">
                <app-cart-summary
                    (routeClick)="toggleCart()"
                ></app-cart-summary>
            </div>

            <div
                *ngIf="menu_open || cart_open"
                class="backdrop"
                (click)="closeMenu()"
            ></div>
        </header>

        <div class="header-space"></div>
    </nb-layout-header>

    <!-- Footer -->
    <nb-layout-footer>
        <small class="text-white" *ngIf="clid">{{ clid.substr(2, 8) }}</small>
        <footer class="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <ul class="site-links">
                                <li>
                                    <input type="checkbox" id="links1" />
                                    <label for="links1">
                                        客戶服務
                                        <nb-icon
                                            icon="arrow-ios-downward"
                                        ></nb-icon>
                                    </label>
                                    <div class="links">
                                        <a [routerLink]="['/auth/register']">
                                            加入會員
                                        </a>
                                        <a [routerLink]="['/products/home']">
                                            網路訂購
                                        </a>
                                        <a [routerLink]="['/store']">
                                            門市資訊
                                        </a>
                                        <a
                                            [routerLink]="[
                                                '/content/page/sales-channel'
                                            ]"
                                        >
                                            通路資訊
                                        </a>
                                        <a [routerLink]="['/content/page/qa']">
                                            常見問題
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" id="links2" />
                                    <label for="links2">
                                        了解更多
                                        <nb-icon
                                            icon="arrow-ios-downward"
                                        ></nb-icon>
                                    </label>
                                    <div class="links">
                                        <a
                                            [routerLink]="[
                                                '/content/page/brand'
                                            ]"
                                        >
                                            品牌理念
                                        </a>
                                        <a
                                            [routerLink]="[
                                                '/content/introduction/info/1'
                                            ]"
                                        >
                                            產品介紹
                                        </a>
                                        <a href="/#ig">顧客見證</a>
                                        <a
                                            [routerLink]="[
                                                '/content/page/recycle'
                                            ]"
                                        >
                                            空瓶回收
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <input type="checkbox" id="links3" />
                                    <label for="links3">
                                        聯絡我們
                                        <nb-icon
                                            icon="arrow-ios-downward"
                                        ></nb-icon>
                                    </label>
                                    <div class="links">
                                        <a
                                            href="https://page.line.me/matthewschoice"
                                            target="_blank"
                                        >
                                            線上客服
                                        </a>
                                        <a
                                            [routerLink]="[
                                                '/customer-service/create'
                                            ]"
                                        >
                                            客服信箱
                                        </a>
                                        <a
                                            href="https://www.104.com.tw/company/bkg6hqo"
                                            target="_blank"
                                        >
                                            人才招募
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-4">
                            <h6 class="my-2">訂閱追蹤</h6>
                            <div class="social-links">
                                <a
                                    href="https://www.facebook.com/matthewschoice"
                                    target="_blank"
                                >
                                    <img
                                        src="/assets/images/social/facebook.svg"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/matthewschoice/"
                                    target="_blank"
                                >
                                    <img
                                        src="/assets/images/social/instagram.svg"
                                    />
                                </a>
                                <a
                                    href="https://www.youtube.com/@user-uj2jp3tj9w/featured"
                                    target="_blank"
                                >
                                    <img
                                        src="/assets/images/social/youtube.svg"
                                    />
                                </a>
                                <a
                                    href="https://page.line.me/matthewschoice"
                                    target="_blank"
                                >
                                    <img src="/assets/images/social/line.svg" />
                                </a>
                                <a [routerLink]="['/customer-service/create']">
                                    <img src="/assets/images/social/mail.svg" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-bottom">
                <div class="container">
                    <div class="row gx-md-5">
                        <div class="col-md-auto d-none d-lg-flex border-lg-end">
                            <img
                                src="/assets/images/logo_white.svg"
                                class="logo my-auto"
                            />
                        </div>
                        <div class="col-md-auto border-lg-end">
                            <table class="mx-auto mx-md-0">
                                <tr>
                                    <td colspan="2" class="pb-1">
                                        <span class="title">
                                            彩宸生活事業(股)有限公司
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pe-3">統編</td>
                                    <td>25181106</td>
                                </tr>
                                <tr>
                                    <td class="pe-3">Add</td>
                                    <td>台南市永康區環工路3號</td>
                                </tr>
                                <tr>
                                    <td class="pe-3">Tel</td>
                                    <td>06-201-0035</td>
                                </tr>
                                <tr>
                                    <td class="pe-3">Email</td>
                                    <td>
                                        <a href="mailto:ask@matthewschoice.com">
                                            ask@matthewschoice.com
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div
                            class="col-md-auto d-flex text-center text-md-start"
                        >
                            <div class="mt-auto">
                                <a
                                    [routerLink]="['/content/page/privacy']"
                                    class="link mt-2"
                                >
                                    隱私說明
                                </a>
                                <span
                                    class="d-md-none mx-3 py-1 border-end"
                                ></span>
                                <a
                                    [routerLink]="['/content/page/anti-fraud']"
                                    class="link mt-1"
                                >
                                    詐騙宣導
                                </a>
                                <div class="copyright mt-2 mt-md-1">
                                    Matthew’s Choice Inc
                                    <br class="d-none d-md-block" />
                                    © 2022 All Rights Reserved
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </nb-layout-footer>

    <!-- 內頁 -->
    <nb-layout-column>
        <router-outlet></router-outlet>
        <!--ngx-spinner [fullScreen]=true>
  		<p style="font-size: 16px; color: white">Loading...</p>
		</ngx-spinner-->

        <button type="button" class="top" (click)="layoutService.gotoTop()">
            <img src="/assets/images/icon/top.svg" />
        </button>
    </nb-layout-column>
</nb-layout>
