import { GetRedirectMap } from './../Redirectfn';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectmap = GetRedirectMap().find(x=>x.from===state.url);
    return redirectmap? this.router.parseUrl(redirectmap.to) : this.router.parseUrl('/error/404');
  }
}

export class redirectmap {
  from: string;
  to: string;
}
