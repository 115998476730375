// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  	production: false,
  	debugger: true,
  	default_shop_id: '1',
  	default_shop_name: '馬修官網驗證區',
    APIUrl: "https://api.matthewschoice-yogurt.com",
	APIUrlReg: "^https?:\/\/.*matthewschoice-yogurt.com",
  FileUrl: "https://api.matthewschoice-yogurt.com/storage",
  paymentUrl: "https://api.matthewschoice-yogurt.com/api/lab-shop/front/go-pay",
  subscriptionPaymentUrl: "https://api.matthewschoice-yogurt.com/api/lab-shop/front/bind-go-pay",
	FBappId: '707900746743754',
	GoogleAPIKey: '659958857708-3svr1jin387vf5l7iep0ia9r3admkts3.apps.googleusercontent.com', //for google login GoogleClientID
	//WebSocketUrl: "http://localhost/websocket/public",
	WebSocketHost: "210.65.139.164:6001",
	webTitle: '馬修嚴選',
	GA: {
		trackingID: 'UA-123182296-24',
		page_view: false,
		addImpression: false,
		productClick: false,
		productDetail: false,
		addCart: false,
		removeCart: false,
		cartSteps: false,
		cartFinish: false
	},
	GTM: {
		cartFinish: true
	},
	//加入,修改,刪除購物車時 讀取購物車是否重新計算折扣
	cartDiscountReload: {
		write: 1,
		read: 0
	},
	lazyLoad_defaultImg: '/assets/images/lazyload.png',
  recaptchSiteKey: '6LfxwHQpAAAAADu6dL2OlJCmiyomXEzUEfDRCJWi',
	smsTimeout: 60,
	favIcon: 'favicon.ico',
  LINELoginUrl: 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1656799451&redirect_uri=https://api.matthewschoice-yogurt.com/api/lab-auth/line-callback&state=get-code&scope=openid%20email%20profile',
	liffID: '1656799451-5Xvboznk',
	GTag: {
		trackingID: 'AW-10970759821',
		actions: {
			pageView: '7ktCCPqLhJwZEI2Fou8o',
			addToCart: 'fXNjCP2LhJwZEI2Fou8o',
			purchase: 'L_hTCICMhJwZEI2Fou8o',
		},
	},
	MetaPixelID: '967428324584003',
    version: '4.1.0'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
