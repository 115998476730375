import { RedirectGuard } from './guards/redirect.guard';
import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule,
    PreloadAllModules,
    ExtraOptions,
} from '@angular/router';
import { CartGuard } from './guards/cart.guard';
import { LoginGuard } from './guards/login.guard';
import { ClidLineGuard } from './guards/clid-line.guard';

import { AdminHomeComponent } from './layout/admin-home/admin-home.component';
import { HomeComponent } from './layout/home/home.component';
import { IndexComponent } from './layout/index/index.component';

const routes: Routes = [
    //系統首頁
    {
        path: '',
        canActivate: [ClidLineGuard], //漸強LINE直接登入
        component: HomeComponent,
        children: [
            //首頁
            {
                path: '',
                component: IndexComponent,
            },
            //產品
            {
                path: 'products',
                loadChildren: () =>
                    import('./product/product.module').then(
                        (m) => m.ProductModule,
                    ),
            },
            //品牌
            {
                path: 'brands',
                loadChildren: () =>
                    import('./brand/brand.module').then((m) => m.BrandModule),
            },
            //行銷活動
            {
                path: 'discount',
                loadChildren: () =>
                    import('./discount-event/discount-event.module').then(
                        (m) => m.DiscountEventModule,
                    ),
            },
            //網站內容
            {
                path: 'content',
                loadChildren: () =>
                    import('./content/content.module').then(
                        (m) => m.ContentModule,
                    ),
            },
            //購物車與結帳
            {
                path: 'cart',
                canActivate: [CartGuard],
                canActivateChild: [CartGuard],
                loadChildren: () =>
                    import('./cart/cart.module').then((m) => m.CartModule),
            },
            //會員中心
            {
                path: 'member',
                canActivate: [LoginGuard],
                canActivateChild: [LoginGuard],
                loadChildren: () =>
                    import('./member/member.module').then(
                        (m) => m.MemberModule,
                    ),
            },
            //訪客訂單
            {
                path: 'order',
                loadChildren: () =>
                    import('./order/order.module').then((m) => m.OrderModule),
            },
            //驗證登入註冊頁面
            {
                path: 'auth',
                loadChildren: () =>
                    import('./auth/auth.module').then((m) => m.AuthModule),
            },
            //線上客服
            /*
			{
				path: 'chat',
				loadChildren: () => import('./websocket/websocket.module').then(m => m.WebsocketModule)
			},
			*/
            //門市據點
            {
                path: 'store',
                loadChildren: () =>
                    import('./store/store.module').then((m) => m.StoreModule),
            },
            //推薦項目（影音購物）
            {
                path: 'recommend',
                loadChildren: () =>
                    import('./recommend/recommend.module').then(
                        (m) => m.RecommendModule,
                    ),
            },

            //站內搜尋
            {
                path: 'search',
                loadChildren: () =>
                    import('./search/search.module').then(
                        (m) => m.SearchModule,
                    ),
            },
            //錯誤頁面
            {
                path: 'error',
                loadChildren: () =>
                    import('./error/error.module').then((m) => m.ErrorModule),
            },

            //客服
            {
                path: 'customer-service',
                loadChildren: () =>
                    import('./customer-service/customer-service.module').then(
                        (m) => m.CustomerServiceModule,
                    ),
            },

            //活動
            /*
			{
				path: 'activity',
				loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule)
			},*/
        ],
    },

    //快速登入註冊頁面（豆寶）
    {
        path: 'quick',
        loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
    },
    //line api不ssr渲染
    {
        path: 'line',
        loadChildren: () =>
            import('./line/line.module').then((m) => m.LineModule),
    },
    //version
    {
        path: 'version',
        loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '**',
        component: IndexComponent,
        canActivate: [RedirectGuard],
    },
];

const routerOptions: ExtraOptions = {
    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 110],
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
